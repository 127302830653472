import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import { Container } from 'layout';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import ConditionalWrapper from 'common/ConditionalWrapper/ConditionalWrapper';

import { IImageBannerProps } from './model';

import './ImageBanner.scss';

const ImageBanner: FC<IImageBannerProps> = ({
  image: [
    {
      properties: { image, link, ariaLabel, imageAlt },
    },
  ],
  withCTA,
}) => {
  const { url, name, target } = link?.[0] || {};

  return (
    <div className="image-banner">
      <Container fluid>
        <div className="image-banner__image">
          <ConditionalWrapper
            condition={!withCTA && url}
            wrapper={(children) => (
              <Link to={url} target={url} aria-label={ariaLabel}>
                {children}
              </Link>
            )}
          >
            <GatsbyImage image={image} alt={imageAlt} className="image-banner__image-img" />
          </ConditionalWrapper>

          {withCTA ? (
            <Button
              to={url}
              ariaLabel={name}
              target={target}
              variant="primary-link"
              iconSuffix="chevron-right"
              classes="image-banner__cta"
            >
              {name}
            </Button>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export default ImageBanner;

export const query = graphql`
  fragment FragmentImageBanner on TImageBanner {
    properties {
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200, srcSetBreakpoints: [360, 760, 1200]) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
          ariaLabel
          link {
            url
            target
            name
          }
        }
      }
      withCTA
    }
    structure
  }
`;
